import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPortfolioItem } from "services/portfolio";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Helmet } from "react-helmet";

const renderOptions = {
  renderNode: {
    "embedded-asset-block": (node) => (
      <img src={`${node.data.target.fields.file.url}`} />
    ),
  },
};

function PortfolioItemPage() {
  const { portfolioItem: portfolioItemName } = useParams();
  const [portfolioItem, setPortfolioItem] = useState(null);

  useEffect(() => {
    getPortfolioItem(portfolioItemName).then((pi) => {
      setPortfolioItem(pi);
    });
  }, [portfolioItemName]);

  if (portfolioItem == null) {
    return "";
  }

  const rendered = !portfolioItem.content
    ? "Hello, I'm currently updating the content. Please check again soon :)"
    : documentToReactComponents(portfolioItem.content, renderOptions);
  return (
    <div class="portfolio-item">
      <Helmet>
        <title>{portfolioItem.title} | Micasa</title>
      </Helmet>
      <h3>{portfolioItem.title}</h3>
      {rendered}
    </div>
  );
}

export default PortfolioItemPage;
