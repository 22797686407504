import LogoSection from "components/LogoSection";
import NavSection from "components/NavSection";
import FooterSection from "components/FooterSection";

function DesktopLayout(props) {
  return (
    <div className="desktop-layout">
      <div className="sidebar">
        <LogoSection />
        <NavSection />
      </div>
      <div className="content">{props.children}</div>
      <FooterSection />
    </div>
  );
}

export default DesktopLayout;
