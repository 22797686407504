import React from "react";
import { Formik } from "formik";
import axios from "axios";

const BobotPage = () => (
  <div>
    <h1>Bobot - Vitamin Group</h1>
    <Formik
      initialValues={{ msg: "" }}
      validate={(values) => {
        const errors = {};
        if (!values.msg) {
          errors.msg = "Required";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, setStatus, resetForm }) => {
        return axios
          .post(
            "https://api.telegram.org/bot1626051427:AAGjKz4oOcbXa8rhLnpNYA234D65UuIr3Mg/sendMessage",
            {
              chat_id: -365112832,
              text: values.msg,
            }
          )
          .then(() => {
            resetForm();
            setStatus("success");
            setInterval(() => {
              setStatus("");
            }, 3000);
            setSubmitting(false);
          })
          .catch((e) => {
            setSubmitting(false);
            setStatus("something wrong has happened");
            setInterval(() => {
              setStatus("");
            }, 3000);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        status,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <input
              type="text"
              name="msg"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Your message..."
              value={values.msg}
            />
          </div>
          {errors.msg && touched.msg && <div>{errors.msg}</div>}
          <button type="submit" disabled={isSubmitting}>
            Submit
          </button>
          {status && <div>{status}</div>}
        </form>
      )}
    </Formik>
  </div>
);

export default BobotPage;
