import { useDisplayMode, useIsMaxYScroll, useLayoutType } from "hooks/hooks";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { getPortfolioWithItems } from "services/portfolio";

function PortfolioPage() {
  const mode = useDisplayMode();
  const { portfolio } = useParams();
  const { url } = useRouteMatch();
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [portfolioDisplayName, setPortfolioDisplayName] = useState("");
  useEffect(() => {
    getPortfolioWithItems(portfolio).then((p) => {
      setPortfolioItems(p.portfolioItems);
      setPortfolioDisplayName(p.displayName);
    });
  }, [portfolio]);

  const portfolioItemsView = portfolioItems.map((pi, idx) => {
    return (
      <PortfolioItem
        key={idx}
        currUrl={url}
        isLastElement={idx === portfolioItems.length - 1}
        {...pi}
      />
    );
  });

  const classNames = `gallery size-${mode}`;
  return (
    <section className={classNames}>
      <Helmet>
        <title>{portfolioDisplayName} | Micasa</title>
      </Helmet>
      {portfolioItemsView}
    </section>
  );
}

function PortfolioItem({ title, imageUrl, slugUrl, currUrl, isLastElement }) {
  const ref = useRef(null);
  const [boundingTop, setBoundingTop] = useState(-1);
  const [height, setHeight] = useState(-1);
  const layoutType = useLayoutType();
  const isMaxScrollY = useIsMaxYScroll();

  useLayoutEffect(() => {
    function updateOffsets() {
      if (ref.current == null) return;
      setBoundingTop(ref.current.getBoundingClientRect().top);
      setHeight(ref.current.offsetHeight);
    }
    window.addEventListener("scroll", updateOffsets);
    window.addEventListener("resize", updateOffsets);
    updateOffsets();

    return () => {
      window.removeEventListener("scroll", updateOffsets);
      window.removeEventListener("resize", updateOffsets);
    };
  }, []);

  const isHovered = calcIsHovered(
    layoutType,
    ref,
    boundingTop,
    height,
    isMaxScrollY,
    isLastElement
  );
  const galleryClass = isHovered
    ? `gallery-item ${layoutType} hover`
    : `gallery-item ${layoutType}`;
  return (
    <Link to={`${currUrl}/${slugUrl}`} ref={ref}>
      <div className={galleryClass}>
        <div
          className="background"
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
        <span className="title">{title}</span>
      </div>
    </Link>
  );
}

function calcIsHovered(
  layoutType,
  ref,
  boundingTop,
  height,
  isMaxScrollY,
  isLastElement
) {
  const navHeight = 160;

  const heightPercent = 0.3;
  if (layoutType !== "mobile" || ref.current === null) {
    return false;
  }

  if (isMaxScrollY) {
    return isLastElement;
  }

  if (
    boundingTop - navHeight < height * 0.3 &&
    boundingTop - navHeight > -height * (1 - heightPercent)
  ) {
    return true;
  }

  return false;
}

export default PortfolioPage;
