function HamCloseBtn({ btnState = 1, onClick = () => {} }) {
  return (
    <div className="hamclose" onClick={() => onClick()}>
      <div className={`bar1 state-${btnState}`} />
      <div className={`bar2 state-${btnState}`} />
      <div className={`bar3 state-${btnState}`} />
    </div>
  );
}

export default HamCloseBtn;
