import FooterSection from "components/FooterSection";
import HamCloseBtn from "components/HamCloseBtn";
import LogoSection from "components/LogoSection";
import NavSection from "components/NavSection";
import { useState } from "react";

function MobileLayout(props) {
  // null, true, false
  const [navState, setNavState] = useState(null);
  const setNavStateFalse = () => setNavState(false);
  return (
    <div className="mobile-layout">
      <div className="sticky-bar">
        <LogoSection />
      </div>
      <div id="nav-button">
        <HamCloseBtn
          btnState={getBtnStateFromNavState(navState)}
          onClick={() => setNavState(!navState)}
        />
      </div>
      <div
        onClick={setNavStateFalse}
        className={"curtain " + getCurtainClass(navState)}
      />
      <NavSection
        extraClassNames={[getNavStateClass(navState)]}
        onClick={setNavStateFalse}
      />
      <div className="content">{props.children}</div>
      <section></section>
      <FooterSection />
    </div>
  );
}

function getNavStateClass(navState) {
  if (navState === null) return "initial";
  return navState ? "active" : "inactive";
}

function getCurtainClass(navState) {
  if (navState == null) return "initial";
  return navState ? "show" : "hide";
}

function getBtnStateFromNavState(navState) {
  if (navState === null) return 1;
  return navState ? 2 : 1;
}

export default MobileLayout;
