import React from "react";
import { Formik } from "formik";
const NodeRSA = require("node-rsa");

const RsaPage = () => (
  <div>
    <h1>Encrypt Decrypt</h1>
    <h2> Generate Key Pair </h2>
    <GenerateKeyPairForm />
    <h2>Encrypt</h2>
    <EncryptForm />
    <h2>Decrypt</h2>
    <DecryptForm />
  </div>
);

const GenerateKeyPairForm = () => (
  <Formik initialValues={{ key: null }} onSubmit={generateKeyPairSubmitHandler}>
    {({ values, handleSubmit, isSubmitting }) => (
      <form onSubmit={handleSubmit}>
        <button type="submit" disabled={isSubmitting}>
          Generate Key Pair
        </button>
        <h3> Public Key </h3>
        <div>
          <textarea
            type="text"
            name="public"
            value={getPublicKey(values.key)}
            disabled={true}
            rows="5"
            cols="100"
          />
        </div>
        <h3> Private Key </h3>
        <div>
          <textarea
            type="text"
            name="private"
            value={getPrivateKey(values.key)}
            disabled={true}
            rows="10"
            cols="100"
          />
        </div>
      </form>
    )}
  </Formik>
);

function generateKeyPairSubmitHandler(_values, { setValues, setSubmitting }) {
  const newKey = new NodeRSA({ b: 512 });
  setValues({
    key: newKey,
  });
  setSubmitting(false);
}

const EncryptForm = () => (
  <Formik
    initialValues={{
      textToEncrypt: null,
      publicKeyBase64: null,
      encryptedText: null,
    }}
    onSubmit={encryptSubmitHandler}
  >
    {({ values, handleSubmit, handleChange, isSubmitting }) => (
      <form onSubmit={handleSubmit}>
        <h3>Public Key</h3>
        <textarea
          type="text"
          name="publicKeyBase64"
          value={values.publicKeyBase64}
          onChange={handleChange}
          rows="5"
          cols="100"
        />

        <h3>Text to encrypt</h3>
        <textarea
          type="text"
          name="textToEncrypt"
          value={values.textToEncrypt}
          onChange={handleChange}
          rows="5"
          cols="100"
        />

        <div>
          <button type="submit" disabled={isSubmitting}>
            Encrypt
          </button>
        </div>
        <h3>Encrypted Text</h3>
        <textarea
          type="text"
          name="encryptedText"
          value={values.encryptedText}
          onChange={handleChange}
          rows="5"
          cols="100"
          disabled="true"
        />
      </form>
    )}
  </Formik>
);

function encryptSubmitHandler(values, { setValues, setSubmitting }) {
  const key = new NodeRSA();
  const publicKey = Buffer.from(values.publicKeyBase64, "base64").toString();
  key.importKey(publicKey, "public");

  const encrypted = key.encrypt(values.textToEncrypt, "base64");
  setValues({
    encryptedText: encrypted,
  });
  setSubmitting(false);
}

const DecryptForm = () => (
  <Formik
    initialValues={{
      textToDecrypt: null,
      privateKeyBase64: null,
      decryptedText: null,
    }}
    onSubmit={decryptSubmitHandler}
  >
    {({ values, handleSubmit, handleChange, isSubmitting }) => (
      <form onSubmit={handleSubmit}>
        <h3>Private Key</h3>
        <textarea
          type="text"
          name="privateKeyBase64"
          value={values.privateKeyBase64}
          onChange={handleChange}
          rows="5"
          cols="100"
        />

        <h3>Text to decrypt</h3>
        <textarea
          type="text"
          name="textToDecrypt"
          value={values.textToDecrypt}
          onChange={handleChange}
          rows="5"
          cols="100"
        />

        <div>
          <button type="submit" disabled={isSubmitting}>
            Decrypt
          </button>
        </div>
        <h3>Decrypted Text</h3>
        <textarea
          type="text"
          name="decryptedText"
          value={values.decryptedText}
          onChange={handleChange}
          rows="5"
          cols="100"
          disabled="true"
        />
      </form>
    )}
  </Formik>
);

function decryptSubmitHandler(values, { setValues, setSubmitting }) {
  const key = new NodeRSA();
  const privateKey = Buffer.from(values.privateKeyBase64, "base64").toString();
  key.importKey(privateKey, "private");

  const decrypted = key.decrypt(values.textToDecrypt, "utf8", "base64");
  setValues({
    decryptedText: decrypted,
  });
  setSubmitting(false);
}

function getPublicKey(key) {
  if (key === null) {
    return "";
  }
  return Buffer.from(key.exportKey("public")).toString("base64");
}

function getPrivateKey(key) {
  if (key === null) {
    return "";
  }
  return Buffer.from(key.exportKey("private")).toString("base64");
}

export default RsaPage;
