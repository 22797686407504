import "App.css";
import { useLayoutType } from "hooks/hooks";
import DesktopLayout from "layouts/DesktopLayout";
import MobileLayout from "layouts/MobileLayout";
import AboutPage from "pages/AboutPage";
import PortfolioPage from "pages/PortfolioPage";
import PortfolioItemPage from "pages/PortfolioItemPage";
import Helmet from "react-helmet";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import BobotPage from "pages/BobotPage";
import RsaPage from "pages/RsaPage";
import ZoomPage from "pages/ZoomPage";

function App() {
  const layoutType = useLayoutType();
  const ChosenLayout = layoutType === "desktop" ? DesktopLayout : MobileLayout;
  return (
    <Router>
      <Helmet>
        <title>Micasa</title>
      </Helmet>
      <div className="App">
        <ChosenLayout>
          <RoutedChildrenContent />
        </ChosenLayout>
      </div>
    </Router>
  );
}

function RoutedChildrenContent() {
  return (
    <Switch>
      <Route exact path="/about">
        <AboutPage />
      </Route>
      <Route path="/encrypt">
        <RsaPage />
      </Route>
      <Route path="/bobot">
        <BobotPage />
      </Route>
      <Route path="/zoom">
        <ZoomPage />
      </Route>
      <Route path="/:portfolio/:portfolioItem">
        <PortfolioItemPage />
      </Route>
      <Route path="/:portfolio">
        <PortfolioPage />
      </Route>
      <Route path="/">
        <AboutPage />
      </Route>
    </Switch>
  );
}

export default App;
