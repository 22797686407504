import { Helmet } from "react-helmet";

function AboutPage({ entity = "home" }) {
  return (
    <section className="about-page">
      <Helmet>
        <title>About | Micasa</title>
      </Helmet>
      <div>
        <h1>
          Hello, welcome to my {entity}!
          <br />
          I'm a creator & designer based in Jakarta.
          <br />
          I mostly, well, create & design.
          <br />
        </h1>
        <p>
          I do Interface design, branding, social media design and others visual
          design. Do check out my works & feel free to contact me for projects
          at{" "}
          <a href="mailto:micasabysylvia@gmail.com">micasabysylvia@gmail.com</a>
        </p>
      </div>
    </section>
  );
}

export default AboutPage;
