import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getAllPortfolios } from "services/portfolio";

function NavSection({ onClick = () => {}, extraClassNames = [] }) {
  const [portfolios, setPortfolios] = useState([]);
  useEffect(() => {
    getAllPortfolios().then((latestPortfolios) => {
      setPortfolios(latestPortfolios);
    });
  }, []);
  const classNames = ["nav", ...extraClassNames];
  const joinedClassNames = classNames.join(" ");
  const portfolioLinks = portfolios.map((p, i) => {
    return <PortfolioLink portfolio={p} onClick={onClick} key={i} />;
  });
  return (
    <section className={joinedClassNames}>
      <nav>
        <ul>
          <li>
            <NavLink activeClassName="selected" onClick={onClick} to="/about">
              About
            </NavLink>
          </li>
          {portfolioLinks}
        </ul>
      </nav>
    </section>
  );
}

function PortfolioLink({ portfolio: { urlText, displayName }, onClick }) {
  return (
    <li>
      <NavLink activeClassName="selected" onClick={onClick} to={`/${urlText}`}>
        {displayName}
      </NavLink>
    </li>
  );
}

export default NavSection;
