import logo from "images/logo.png";
import { Link } from "react-router-dom";

function LogoSection() {
  return (
    <section className="logo">
      <Link to="/">
        <img src={logo} alt="logo" />
      </Link>
    </section>
  );
}

export default LogoSection;
