import { useEffect } from "react";

function ZoomPage() {
  useEffect(() => {
    window.location.href =
      "https://us02web.zoom.us/j/82529753755?pwd=S0NWSXBpaUtFak9OdDVaU2hxQVdKQT09";
  }, []);

  return null;
}

export default ZoomPage;
