import client from "services/contentful";
import NodeCache from "node-cache";

const cache = new NodeCache();
export function getAllPortfolios() {
  return cacheFetch(getAllPortfoliosUncached, "allPortfolios", 60);
}

function getAllPortfoliosUncached() {
  return client
    .getEntries({
      content_type: "portfolioGroup",
      select: "fields.urlText,fields.displayName",
      order: "fields.order",
    })
    .then((entries) => entries.items)
    .then((items) => items.map((item) => buildPortfolio(item)));
}

export function getPortfolioWithItems(portfolioName) {
  return cacheFetch(
    () => getPortfolioWithItemsUncached(portfolioName),
    `portfolioWithItems:${portfolioName}`,
    60
  );
}

function getPortfolioWithItemsUncached(portfolioName) {
  return client
    .getEntries({
      content_type: "portfolioGroup",
      "fields.urlText": portfolioName,
    })
    .then((entries) => entries.items)
    .then((items) => {
      if (items.length === 0) {
        return [];
      }
      const portfolio = buildPortfolio(items[0]);
      const portfolioItems = buildPortfolioItems(
        items[0].fields.portfolioItems || []
      );
      return { ...portfolio, portfolioItems };
    });
}

export function getPortfolioItem(portfolioItemName) {
  return client
    .getEntries({
      content_type: "portfolioItem",
      "fields.slugUrl": portfolioItemName,
    })
    .then((entries) => entries.items)
    .then((items) => (items.length > 0 ? items[0] : null))
    .then(buildPortfolioItem);
}

function buildPortfolio(portfolio) {
  return {
    urlText: portfolio.fields.urlText,
    displayName: portfolio.fields.displayName,
  };
}

function buildPortfolioItems(portfolioItems) {
  return portfolioItems.map(buildPortfolioItem);
}

function buildPortfolioItem(portfolioItem) {
  return {
    title: portfolioItem.fields.title,
    imageUrl: portfolioItem.fields.image.fields.file.url,
    slugUrl: portfolioItem.fields.slugUrl,
    content: portfolioItem.fields.content,
  };
}

function cacheFetch(promiseFn, cacheKey, ttlInSec) {
  const cached = cache.get(cacheKey);
  if (cached !== undefined) {
    return Promise.resolve(cached);
  }

  return promiseFn().then((res) => {
    cache.set(cacheKey, res, ttlInSec);
    return res;
  });
}
