import { useEffect, useLayoutEffect, useState } from "react";

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([
        document.documentElement.clientWidth,
        document.documentElement.clientHeight,
      ]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const modes = [
  { width: 480, code: "xs" },
  { width: 768, code: "sm" },
  { width: 1024, code: "md" },
  { width: 1200, code: "lg" },
  { width: 9999, code: "xl" },
];

export function useDisplayMode() {
  const [width] = useWindowSize();
  for (let mode of modes) {
    if (width <= mode.width) return mode.code;
  }
  return modes[modes.length - 1].code;
}

export function useLayoutType() {
  const mode = useDisplayMode();
  return mode === "xs" || mode === "sm" ? "mobile" : "desktop";
}

function useWindowScrollY() {
  const [windowScrollY, setWindowScrollY] = useState(0);

  useEffect(() => {
    function updateWindowScrollY() {
      setWindowScrollY(window.pageYOffset);
    }
    window.addEventListener("scroll", updateWindowScrollY);
    updateWindowScrollY();
    return () => window.removeEventListener("resize", updateWindowScrollY);
  }, []);

  return windowScrollY;
}

export function useIsMaxYScroll() {
  const windowScrollY = useWindowScrollY();
  const limit =
    Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    ) - window.innerHeight;

  return Math.abs(windowScrollY - limit) < 1.0;
}
